import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('text.basicInfo'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.luckyDraw.rankingPriority'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'prizeable_type',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.luckyDraw.prizeableType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true
      }
    }, {
      span: 8,
      prop: 'prizeable_id',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.luckyDraw.prizeableId'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 24,
      prop: 'remote_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_image_url'
      }
    }, {
      span: 12,
      prop: 'count',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.count'),
      placeholder: i18n.t('form.luckyDraw.countRule'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'probability',
      name: 'input',
      type: 'number',
      label: i18n.t('form.luckyDraw.probability'),
      placeholder: i18n.t('form.luckyDraw.probabilityRule'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
